import { ColletionTable } from 'utils/type/collection.type'
import avatar3 from 'assets/images/avatar3.png'
import avatar2 from 'assets/images/avatar2.png'

export const data: ColletionTable[] = [
  {
    totalVolume: 45323453,
    collectionName: [avatar3, 'NFT Fan Token'],

    owners: '--',
    totalSold: 769.0,
    NoOfNfts: 457.07,
  },
  {
    totalVolume: 45323453,
    collectionName: [avatar3, 'NFT Fan Token'],

    owners: '--',
    totalSold: 769.0,
    NoOfNfts: 457.07,
  },
  {
    totalVolume: 45323453,
    collectionName: [avatar2, 'NFT Fan Token'],

    owners: '--',
    totalSold: 769.0,
    NoOfNfts: 457.07,
  },
  {
    totalVolume: 45323453,
    collectionName: [avatar2, 'NFT Fan Token'],

    owners: '--',
    totalSold: 769.0,
    NoOfNfts: 457.07,
  },
]
