import React, { useState } from 'react'
import {
  Text,
  Flex,
  Button,
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  useDisclosure,
  ModalHeader,
  ModalCloseButton,
  ModalFooter,
  AvatarBadge,
  Avatar,
  HStack,
  IconButton,
  VStack,
} from '@chakra-ui/react'
import MainLayout from 'layout'
import Header from 'components/header'
import { DefaultImage } from 'assets/icons/login'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import { ColorResult, SwatchesPicker } from 'react-color'
import CustomInput from 'components/customInput'
import { Edit } from 'assets/icons'
export default function AdminSettings() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [color, setColor] = useState<string>('#dfefe3')
  return (
    <MainLayout>
      <>
        <Header />
        <Box
          className="glass-effect"
          bg="primary.100"
          borderRadius="7px"
          color="white"
          py="3rem"
          textAlign="center"
          border="1px"
          borderStyle="dashed"
          maxW="968px"
          w="100%"
          ml="auto"
        >
          <DefaultImage />
          <Text className="fs--14 fw--300" color="white" textAlign="center">
            Upload cover image
          </Text>
        </Box>
        <Box
          rounded="7px"
          bg="primary.100"
          padding="25px"
          mt="75px"
          maxW="968px"
          w="100%"
          ml="auto"
        >
          {/* Upload content Flex Start*/}
          <Flex rounded="7px" alignItems="end" justifyContent="end" mt="-61px">
            <Text className="fs--18 fw--600" mr="10px" color="white" as="span">
              Personalization
            </Text>{' '}
            <Avatar
              size="xl"
              icon={<DefaultImage />}
              bgColor="#1C1D2C"
              border="1px solid gray"
            >
              <AvatarBadge
                as={'image'}
                bg="primary.500"
                border="0"
                left="0"
                bottom="8px"
                boxSize="20px"
              >
                <MdOutlineModeEditOutline fontSize="12px" color="black" />
              </AvatarBadge>
            </Avatar>
          </Flex>
          <HStack my="20px">
            {' '}
            <Box flex={0.6}>
              {' '}
              <IconButton
                size="sm"
                bg="blackAlpha.300"
                aria-label="Search database"
                icon={<Edit />}
                _hover={{}}
              />
            </Box>
            <HStack flex={0.4} justify="space-between">
              <ColorPicker color={color} setColor={setColor} />

              <Text className="fs--14 " color="white">
                Change Background color
              </Text>
            </HStack>
          </HStack>
          <HStack>
            {' '}
            <Box flex={0.6}>
              {' '}
              <IconButton
                size="sm"
                bg="blackAlpha.300"
                aria-label="Search database"
                icon={<Edit />}
                onClick={onOpen}
                _hover={{}}
              />
            </Box>
            <HStack flex={0.4} justify="space-between">
              <Text>*******</Text>

              <Text className="fs--14 " color="white">
                Change The Password
              </Text>
            </HStack>
          </HStack>

          <Flex mt="30px">
            <Button className="fs--14 fw--200" w="160px" rounded="full">
              Save Changes
            </Button>
            <Button
              className="fs--14 fw--200"
              w="117px"
              colorScheme="secondary"
              ml="12px"
              color="white"
              rounded="full"
            >
              Cancel
            </Button>
          </Flex>
        </Box>
        <ChangePasswordModal isOpen={isOpen} onClose={onClose} />
      </>
    </MainLayout>
  )
}
type props = {
  isOpen: boolean
  onClose: () => void
}
function ChangePasswordModal({ onClose, isOpen }: props) {
  return (
    <Modal size="sm" isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="#2F2F3F">
        <ModalCloseButton
          right="90%"
          top="7%"
          bg="primary.500"
          // bgColor="primary"
          color="white"
          rounded="50%"
          size="sm"
        />
        <ModalHeader
          textAlign="end"
          as={Text}
          className="fs--16 fw--700"
          color="white"
          // py="0"
        >
          Change Password
        </ModalHeader>
        <ModalBody dir="rtl">
          <VStack gap="14px">
            {' '}
            <CustomInput type="password" placeholder="Old password" />{' '}
            <CustomInput type="password" placeholder="Create new password" />{' '}
            <CustomInput type="password" placeholder="Confirm new password" />
          </VStack>
        </ModalBody>

        <ModalFooter justifyContent="start">
          <Button className="fs--14 fw--300" rounded="20px" w="117px" mr={3}>
            Save
          </Button>
          <Button
            className="fs--14 fw--300"
            rounded="20px"
            w="117px"
            colorScheme="secondary"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
interface IColorPickerProps {
  color: string
  setColor: (newColor: string) => void
}

const ColorPicker = ({ color, setColor }: IColorPickerProps) => {
  const [displayColorPicker, setDisplayColorPicker] = useState<boolean>(false)

  const handleColorChange = (newColor: ColorResult) => {
    setColor(newColor.hex)
    setDisplayColorPicker(false)
  }

  const handleColorPickerClick = () => {
    setDisplayColorPicker(!displayColorPicker)
    console.log('hello')
  }
  return (
    <>
      <Button
        bg={color}
        _hover={{}}
        onClick={handleColorPickerClick}
        position="relative"
      >
        {color}
      </Button>
      {displayColorPicker && (
        <Box
          onClick={() => setDisplayColorPicker(false)}
          style={{
            position: 'absolute',
          }}
        >
          <SwatchesPicker color={color} onChange={handleColorChange} />
        </Box>
      )}
    </>
  )
}
