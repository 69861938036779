import React from 'react'
import {
  Flex,
  Image,
  Text,
  Center,
  Box,
  HStack,
  IconButton,
} from '@chakra-ui/react'
import sideBarCard from 'assets/images/sidebar/sideBarCard.svg'
import sidebarCardIcon from 'assets/images/sidebar/ntfIcon.svg'
import graphIcon from 'assets/images/sidebar/graph.svg'
import mainLogo from 'assets/icons/login/logo.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { routeTabs } from 'utils/data/routeTabs.data'
import { HiOutlineLogout } from 'react-icons/hi'
export default function AdminSideBar() {
  const location = useLocation()
  const navigate = useNavigate()
  return (
    <>
      <Image mt="7px" mb="50px" px="15px" src={mainLogo} />
      <Box
        borderRadius="20px 0px 0px 20px"
        p="20px"
        bgImage={sideBarCard}
        backgroundSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
      >
        <HStack spacing="5rem" mb="17px">
          <Center
            rounded="15px"
            bgGradient="linear(180deg, #C03DFD 0%, #4F81FD 100%) 0% 0%"
            p="10px "
          >
            <Image src={sidebarCardIcon} />
          </Center>
          <Image src={graphIcon} />
        </HStack>
        <Box>
          <Text className="fs--26 fw--600" mt="3px" color="#D57CFF">
            5431
          </Text>
          <Text className="fs--14 fw--600" color="white">
            Number of NFTs sold
          </Text>
        </Box>
      </Box>
      <Flex direction="column" color="white" align="end" mt="15px">
        {routeTabs.map((tab, index) => (
          <Flex
            key={index}
            w="80%"
            mb="10px"
            py="13px"
            pr="25px"
            align="center"
            cursor="pointer"
            justifyContent="end"
            className={
              location.pathname.includes(tab?.path)
                ? 'fs--14 fw--600 sideBarMenuGradientActive'
                : 'fs--14 fw--600 sideBarMenuGradient'
            }
            onClick={() => navigate(`/${tab?.path}`)}
          >
            {/* <Link to={`/${tab?.path}`}> */}
            <Text
              mr="16px"
              color={
                location.pathname.includes('admin-dashboard')
                  ? 'white'
                  : 'primary.51'
              }
            >
              {tab?.name}
            </Text>

            {/* <Image src={dasboardlogo} /> */}
            {/* </Link> */}
            {tab?.icon}
          </Flex>
        ))}
      </Flex>

      <IconButton
        mt="1rem"
        ml="80%"
        aria-label="logout"
        icon={<HiOutlineLogout color="black" fontSize="21px" />}
      />
    </>
  )
}
