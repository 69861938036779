import { extendTheme, withDefaultColorScheme } from '@chakra-ui/react'
import { Text } from './themeComponents/Text'
export const myTheme = extendTheme(
  {
    fonts: {
      body: 'Gotham',
      heading: 'Gotham',
    },
    colors: {
      primary: {
        99: '#99ACD226', //upper shadow
        100: '#FFFFFF1A ', //transparent background code
        200: '#99ACD2', //border color for the the boxes
        300: '#C03DFD',
        400: '#4F81FD',
        500: 'linear-gradient(90deg, #C03DFD, #4F81FD)',
        600: 'linear-gradient(90deg, #4F81FD , #C03DFD)',
      },
      secondary: { 500: 'linear-gradient(90deg,#5B4582 , #64647A)' },
      text: '#99ACD2',
      cardbg: '#1E1F27',
    },
    styles: {
      global: {
        body: {
          bg: '#131623',
        },
      },
    },
    components: {
      Text,
    },
  },
  withDefaultColorScheme({ colorScheme: 'primary' }),
)
