import React from 'react'
import {
  HStack,
  Flex,
  Avatar,
  Button,
  Heading,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react'
import { GiFlowerStar } from 'react-icons/gi'
import img4 from 'assets/images/1.png'
import { ChevronDownIcon } from '@chakra-ui/icons'
interface Props {
  onOpen: () => void
  buttonName: string
  headingPurple?: string
  headingWhite?: string
}
export default function ActionButtonAndHeading({
  onOpen,
  buttonName,
  headingPurple,
  headingWhite,
}: Props) {
  return (
    <>
      <Flex justify="space-between">
        <Button
          className="fs--14 fw--400"
          rounded="full"
          w="140px"
          onClick={onOpen}
        >
          {buttonName}
        </Button>
        {headingPurple && headingWhite ? (
          <HStack>
            <Heading color="white" fontSize="24px">
              {headingWhite}
            </Heading>
            <Heading color="primary.300" fontSize="24px">
              {headingPurple}
            </Heading>
          </HStack>
        ) : (
          <Menu>
            <MenuButton
              as={Button}
              variant="ghost"
              color="white"
              _active={{}}
              leftIcon={<ChevronDownIcon />}
              rightIcon={
                <Avatar
                  src={img4}
                  objectFit="cover"
                  className="gradient-border-mask"
                />
              }
            >
              NFT Fan
            </MenuButton>
            <MenuList bg="cardbg" dir="rtl" border="2px solid #232C47">
              {[
                'NFT Fan Token',
                'Top Shot Creator',
                'Rangers Football',
                'Road to the Super Ball',
              ].map(menu => (
                <MenuItem bg="cardbg" key={menu} color="white" gap="5px">
                  <GiFlowerStar style={{ fill: 'url(#blue-gradient)' }} />
                  {menu}
                </MenuItem>
              ))}
            </MenuList>
          </Menu>
        )}
      </Flex>
    </>
  )
}
