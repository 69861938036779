import React from 'react'
export const CheckMark = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="59.849"
      height="59.874"
      viewBox="0 0 59.849 59.874"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.127"
          y1="0.335"
          x2="1.278"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#c03dfd" />
          <stop offset="1" stopColor="#4f81fd" />
        </linearGradient>
        <linearGradient
          id="linear-gradient-2"
          x1="0.5"
          x2="0.5"
          y2="1"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#b10623" />
          <stop offset="1" stopColor="#ff748c" />
        </linearGradient>
      </defs>
      <g
        id="_Pngtree_check-mark-vector-icon_5552269"
        data-name="—Pngtree—check-mark-vector-icon_5552269"
        transform="translate(-300.5 2699.926)"
      >
        <path
          id="Path_87466"
          data-name="Path 87466"
          d="M329.028-2699.9a30.074,30.074,0,0,0-18.673,7.684c-.467.419-1.577,1.522-1.986,1.968a30.049,30.049,0,0,0-7.754,17.476,23.127,23.127,0,0,0-.115,2.744,23.8,23.8,0,0,0,.115,2.769,30.014,30.014,0,0,0,6.626,16.221,30.085,30.085,0,0,0,15.987,10.114,30.012,30.012,0,0,0,11.351.581,29.985,29.985,0,0,0,15.892-7.395c.514-.459,1.724-1.669,2.183-2.183a30.331,30.331,0,0,0,5.5-8.777,30.043,30.043,0,0,0,2.081-8.537,23.672,23.672,0,0,0,.115-2.757,23.667,23.667,0,0,0-.115-2.756,29.862,29.862,0,0,0-1.058-5.588c-.2-.688-.252-.813-.394-.9a.506.506,0,0,0-.407-.065,3.767,3.767,0,0,0-1.275,1.1,3.158,3.158,0,0,0-.434,2.582,26.817,26.817,0,0,1,.432,5.656c-.037,1-.077,1.517-.182,2.377a26.434,26.434,0,0,1-4.154,11.344,25.965,25.965,0,0,1-13.606,10.418,25.806,25.806,0,0,1-6.5,1.23c-.574.037-2.258.037-2.856,0a26.1,26.1,0,0,1-3.6-.467,25,25,0,0,1-13.786-7.931,24.506,24.506,0,0,1-6.07-14,20.759,20.759,0,0,1-.095-2.2,22.477,22.477,0,0,1,.329-4.021,23.789,23.789,0,0,1,5.658-11.858c.457-.519,1.539-1.6,2.063-2.058a23.261,23.261,0,0,1,13.035-5.7,23.489,23.489,0,0,1,4.491-.012,22.7,22.7,0,0,1,6.781,1.734,5.189,5.189,0,0,0,1.894.494,5.024,5.024,0,0,0,3.338-.99,8.154,8.154,0,0,0,1.786-1.924,1.7,1.7,0,0,0,.439-1.225,1.3,1.3,0,0,0-.247-.763c-.2-.3-.382-.424-1.512-1.021a29.994,29.994,0,0,0-11.656-3.33A35.66,35.66,0,0,0,329.028-2699.9Z"
          fill="url(#linear-gradient)"
        />
        <path
          id="Path_87467"
          data-name="Path 87467"
          d="M940.732-2381.506a1.632,1.632,0,0,0-.387.135c-.182.092-1.345,1.1-13.447,11.68-8.627,7.542-13.287,11.6-13.359,11.633a.7.7,0,0,1-.314.052.549.549,0,0,1-.339-.075c-.11-.058-1.165-1.073-4.815-4.615-2.57-2.5-4.852-4.713-5.072-4.927-.549-.531-.8-.659-1.285-.659a1.461,1.461,0,0,0-1.32.811,1.467,1.467,0,0,0,.142,1.589c.257.4,9.47,14.577,9.642,14.836a4.138,4.138,0,0,0,.861.886,3.744,3.744,0,0,0,1.355.589,4.664,4.664,0,0,0,1.345,0,3.538,3.538,0,0,0,1.669-.851c.13-.122,24.294-26.03,26.647-28.57a1.739,1.739,0,0,0,.389-.668,1.686,1.686,0,0,0,0-.773A1.5,1.5,0,0,0,940.732-2381.506Z"
          transform="translate(-584.761 -310.45)"
          fill="url(#linear-gradient-2)"
        />
      </g>
    </svg>
  )
}
export const DefaultImage = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="34.402"
      height="25.358"
      viewBox="0 0 24.402 15.358"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="0.5"
          x2="0.924"
          y2="1.132"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0" stopColor="#c03dfd" />
          <stop offset="1" stopColor="#4f81fd" />
        </linearGradient>
      </defs>
      <g id="Group_63290" data-name="Group 63290" transform="translate(0)">
        <g id="Group_63276" data-name="Group 63276" transform="translate(0)">
          <g id="Group_63275" data-name="Group 63275">
            <path
              id="Path_227"
              data-name="Path 227"
              d="M604.838,680.967a9.969,9.969,0,0,1-1.2.036h-20a5.749,5.749,0,0,1-.8-.13.865.865,0,0,1-.531-.346c-2.224-3.53,6.228-11.467,9.465-13.142,3.358-1.738,2.489,5.4,4.32,6.668s3.185-3.669,5.322-1.931S608.947,678.83,604.838,680.967Z"
              transform="translate(-581.937 -665.645)"
              fill="url(#linear-gradient)"
            />
            <circle
              id="Ellipse_2"
              data-name="Ellipse 2"
              cx="2.321"
              cy="2.321"
              r="2.321"
              transform="translate(16.804)"
              fill="url(#linear-gradient)"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}
