/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import { Table, Thead, Tbody, Tr, Th, Td, chakra } from '@chakra-ui/react'
import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons'
import {
  useReactTable,
  flexRender,
  getCoreRowModel,
  ColumnDef,
  SortingState,
  getSortedRowModel,
} from '@tanstack/react-table'

export type DataTableProps<Data extends object> = {
  data: Data[]
  columns: ColumnDef<Data, any>[]
}

export default function DataTable<Data extends object>({
  data,
  columns,
}: DataTableProps<Data>) {
  const [sorting, setSorting] = React.useState<SortingState>([])
  const table = useReactTable({
    columns,
    data,
    getCoreRowModel: getCoreRowModel(),
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      sorting,
    },
  })

  return (
    <Table dir="rtl">
      <Thead>
        {table
          .getHeaderGroups()
          .map(
            (headerGroup: {
              id: React.Key | null | undefined
              headers: any[]
            }) => (
              <Tr key={headerGroup.id}>
                {headerGroup.headers.map(header => {
                  const meta: any = header.column.columnDef.meta
                  return (
                    <Th
                      fontSize={14}
                      key={header.id}
                      onClick={header.column.getToggleSortingHandler()}
                      isNumeric={meta?.isNumeric}
                      style={{
                        textTransform: 'capitalize',
                        // textAlign: 'center',
                        borderBottom: '0',
                        color: 'white',
                        width: 'max-content',
                      }}
                      pr="0"
                      filter="auto"
                      className="fs--14 fw--600"
                    >
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext(),
                      )}

                      <chakra.span pl="4">
                        {header.column.getIsSorted() ? (
                          header.column.getIsSorted() === 'desc' ? (
                            <TriangleDownIcon aria-label="sorted descending" />
                          ) : (
                            <TriangleUpIcon aria-label="sorted ascending" />
                          )
                        ) : null}
                      </chakra.span>
                    </Th>
                  )
                })}
              </Tr>
            ),
          )}
      </Thead>
      <Tbody>
        {table
          .getRowModel()
          .rows.map(
            (row: {
              id: React.Key | null | undefined
              getVisibleCells: () => any[]
            }) => (
              <Tr
                key={row.id}
                css={`
                  :last-child td {
                    border: none;
                  }
                `}
              >
                {row
                  .getVisibleCells()
                  .map(
                    (cell: {
                      column: { columnDef: { meta: any; cell: any } }
                      id: React.Key | null | undefined
                      getContext: () => any
                    }) => {
                      const meta: any = cell.column.columnDef.meta
                      return (
                        <Td
                          style={{ textAlign: 'start' }}
                          key={cell.id}
                          isNumeric={meta?.isNumeric}
                          position="relative"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                          )}
                        </Td>
                      )
                    },
                  )}
              </Tr>
            ),
          )}
      </Tbody>
    </Table>
  )
}
