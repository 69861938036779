import { DashboardCard } from 'utils/type/dashboard.type'
import sidenft1 from 'assets/images/side1.png'
import sidenft2 from 'assets/images/side2.png'
import sidenft3 from 'assets/images/side3.png'
import sidenft4 from 'assets/images/side4.png'
import sidenft5 from 'assets/images/side5.png'
import sidenft6 from 'assets/images/side6.png'
import sidenft7 from 'assets/images/side7.png'
import avatar1 from 'assets/images/avatar1.png'
import avatar2 from 'assets/images/avatar2.png'
import avatar3 from 'assets/images/avatar3.png'
import main1 from 'assets/images/main1.png'
import main2 from 'assets/images/main2.png'
import main3 from 'assets/images/main3.png'
import main4 from 'assets/images/main4.png'
import main5 from 'assets/images/main5.png'

export const dashboardCard: DashboardCard[] = [
  {
    subNft1: sidenft1,
    subNft2: sidenft2,
    mainNft: main1,
    avatar: avatar1,
    avgPrice: 4625418,
    total: 3265254,
  },
  {
    subNft1: sidenft3,
    subNft2: sidenft4,
    mainNft: main2,
    avatar: avatar2,
    avgPrice: 4625418,
    total: 3265254,
  },
  {
    subNft1: sidenft5,
    subNft2: sidenft6,
    mainNft: main3,
    avatar: avatar1,
    avgPrice: 4625418,
    total: 3265254,
  },
  {
    subNft1: sidenft7,
    subNft2: sidenft1,
    mainNft: main4,
    avatar: avatar3,
    avgPrice: 4625418,
    total: 3265254,
  },
  {
    subNft1: sidenft2,
    subNft2: sidenft2,
    mainNft: main5,
    avatar: avatar2,
    avgPrice: 4625418,
    total: 3265254,
  },
  {
    subNft1: sidenft1,
    subNft2: sidenft2,
    mainNft: main1,
    avatar: avatar1,
    avgPrice: 4625418,
    total: 3265254,
  },
]
