import React, { useState } from 'react'
import {
  Image,
  Button,
  Text,
  Stack,
  Flex,
  VStack,
  Avatar,
  AvatarBadge,
  ButtonGroup,
  Box,
  HStack,
  Select,
  InputGroup,
  InputLeftElement,
  RadioGroup,
  Radio,
  Textarea,
  Heading,
  Input,
} from '@chakra-ui/react'
import mainLogo from 'assets/icons/login/logo.svg'
import backgroundImage from 'assets/images/backgroundImage.png'
import { useNavigate } from 'react-router-dom'
import { DefaultImage } from 'assets/icons/login'
import { MdOutlineModeEditOutline } from 'react-icons/md'
import CustomInput from 'components/customInput'
export default function Authentication() {
  const navigate = useNavigate()
  const [authType, setauthType] = useState('login')
  const handleAuthPassword = () => {
    setauthType('Forgot Password')
  }

  // const handlePasswordChange =()=>{
  //  alert('Login Functionality is comming soon')
  // }
  const handleLogin = () => {
    alert('Login Functionality is comming soon')
    navigate('/admin-dashboard')
  }
  const handleAuthAccount = () => {
    setauthType('Create Account')
  }
  const handleAuthLogin = () => {
    setauthType('login')
  }
  return (
    <Stack
      border="2px"
      minH="100vh"
      bgImage={backgroundImage}
      backgroundSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
    >
      {/* Project Logo Row Start*/}
      <Flex mt="74px" mr="77px">
        <Image src={mainLogo} />
      </Flex>
      {/* Project Logo Row End*/}
      {/* Component Box Start*/}
      {authType === 'login' ? (
        <Flex alignItems="center" justifyContent="center">
          <Login
            loginUser={handleLogin}
            goToPasswordChange={handleAuthPassword}
            goToCreateAccount={handleAuthAccount}
            componentType={authType}
          />
        </Flex>
      ) : authType === 'Create Account' ? (
        <Flex alignItems="center" justifyContent="center">
          <CreateAccount componentType={authType} gotoLogin={handleAuthLogin} />
        </Flex>
      ) : (
        <Flex alignItems="center" justifyContent="center">
          <ForgotPassword
            gotoLogin={handleAuthLogin}
            componentType={authType}
          />
        </Flex>
      )}
      {/* Component Box End*/}
    </Stack>
  )
}
type props = {
  componentType: string
  loginUser: () => void
  goToPasswordChange: () => void
  goToCreateAccount: () => void
}
function Login({
  componentType,
  goToPasswordChange,
  goToCreateAccount,
  loginUser,
}: props) {
  return (
    <Box maxW="350px" w="100%" m="0 auto">
      <Text
        className="fs--36"
        textTransform="capitalize"
        color="white"
        mb="64px"
        textAlign="center"
      >
        {componentType}
      </Text>

      <CustomInput type="text" placeholder="Email" />
      <Box my="10px">
        {' '}
        <CustomInput type="password" placeholder="Password" />
      </Box>

      <Text
        cursor="pointer"
        onClick={goToPasswordChange}
        className="fs--14 fw--200"
        mt="12px"
        mb="32px"
        color="white"
      >
        did you forget your password?
      </Text>

      {/* This box should be converted to the common component for the input box and the image should be optional (Start) */}
      <Button
        width="100%"
        mb="30px"
        rounded="23px"
        className="fs--16 fw--400 "
        onClick={loginUser}
      >
        <Text>{componentType}</Text>
      </Button>
      <Flex className="fs--14 fw--400" align="center">
        <Text cursor="pointer" color="white">
          You still dont have an account?
        </Text>
        <Button
          variant="link"
          color="white"
          mx="6px"
          className="fs--14 fw--400"
          onClick={goToCreateAccount}
        >
          Sign up
        </Button>
      </Flex>
    </Box>
  )
}
type propsCreateAccount = {
  componentType: string
  gotoLogin: () => void
}
function CreateAccount({ componentType, gotoLogin }: propsCreateAccount) {
  const [value, setValue] = React.useState('1')
  return (
    <Box
      w="964px"
      className="fs--14 fw--400 glass-effect"
      bg="transparent"
      p="50px 85px "
    >
      <Text
        className="fs--36 fw--600"
        textTransform="capitalize"
        color="white"
        mb="34px"
        textAlign="center"
      >
        {componentType}
      </Text>
      <HStack justify="space-between" mb="37px">
        {' '}
        <Text className="fs--16 fw--400">Select Logo</Text>
        <Avatar size="xl" icon={<DefaultImage />} bgColor="#1C1D2C">
          <AvatarBadge
            as={'image'}
            bg="primary.500"
            border="0"
            boxSize="20px"
            bottom="5px"
            right="5px"
          >
            <MdOutlineModeEditOutline fontSize="12px" color="white" />
          </AvatarBadge>
        </Avatar>
      </HStack>
      <HStack gap="35px" align="end">
        <VStack flex={0.5} gap="14px">
          <RadioGroup onChange={setValue} value={value} color="white" w="100%">
            <HStack justify="space-between">
              <Radio value="1">Club</Radio>
              <Radio value="2">Professional Athlete</Radio>
            </HStack>
          </RadioGroup>
          <CustomInput type="text" placeholder="club name" />
          <CustomInput type="email" placeholder="Email" />
          <Select
            placeholder="Choose category"
            color="white"
            bgColor="#41424A"
            borderColor="#2F3852"
            rounded="full"
          >
            <option value="option1">Option 1</option>
            <option value="option2">Option 2</option>
            <option value="option3">Option 3</option>
          </Select>
          <InputGroup>
            <InputLeftElement
              pointerEvents="none"
              color="gray.300"
              fontSize="1.2em"
            >
              {' '}
              +
            </InputLeftElement>

            <CustomInput type="text" placeholder="social link" />
          </InputGroup>
        </VStack>
        <VStack flex={0.5} gap="14px" align="start">
          {' '}
          <Textarea
            mt="1rem"
            focusBorderColor="primary.300"
            borderWidth="1px"
            placeholder="Tell us what you need"
            rounded="10px"
            borderColor="#2F3852"
            bgColor="#41424A"
            textColor="white"
            size="md"
            resize="vertical"
            h="163px"
          />
          <CustomInput type="password" placeholder="Password" />{' '}
          <Button
            className="fs--16 fw--400"
            color="white"
            w="100%"
            rounded="full"
            onClick={gotoLogin}
          >
            Sign in
          </Button>{' '}
        </VStack>
      </HStack>
      <HStack mt="22px">
        <Text flex={0.5}></Text>
        <Text flex={0.5}>
          <Button
            // mx="6px"
            mr="16px"
            ml="6px"
            variant="link"
            color="white"
            className="fs--14 fw--400"
          >
            Sign In
          </Button>
          You have an Account?{' '}
        </Text>
      </HStack>
    </Box>
  )
}

type forgotProps = {
  componentType: string
  gotoLogin: () => void
}
function ForgotPassword({ gotoLogin }: forgotProps) {
  const [stepCount, setstepCount] = useState(0)
  return (
    <Stack m={0} pt="80px">
      {stepCount === 0 ? (
        <VStack gap="25px">
          <Heading className="fs--35 fw--600" color="white">
            Forgot Your Password?
          </Heading>
          <Text className="fs--14 fw--500" color="gray.400">
            Enter your email to reset your password
          </Text>
          {/* This box should be converted to the common component for the input box and the image should be optional (Start) */}

          <CustomInput type="email" placeholder="Email" />

          {/* This box should be converted to the common component for the input box and the image should be optional (Start) */}
          <ButtonGroup
            display="flex"
            flexDirection="column"
            alignItems="center"
            w="100%"
          >
            <Button
              onClick={() => {
                setstepCount(1)
              }}
              className="fs--14 fw--300 "
              rounded="23px"
              mb="16px"
              w="100%"
            >
              Submit
            </Button>
            <Button
              rounded="23px"
              className="fs--14 fw--400 btnScandaryMain"
              onClick={gotoLogin}
              w="100%"
            >
              Back to login
            </Button>
          </ButtonGroup>
        </VStack>
      ) : stepCount === 1 ? (
        <Flex direction="column" alignItems="center" w="444px">
          <Flex alignItems="center" justifyContent="center" direction="column">
            <Text className="fs--36 fw--600" mb="26px" color="white">
              Enter security code
            </Text>
            <Text
              className="fs--14 fw--400"
              textAlign="center"
              color="gray.300"
              mb="29px"
            >
              We sent a 6-digit code to email@xxx.com. This code will expire
              after 59 second.
            </Text>
          </Flex>
          {/* This box should be converted to the common component for the input box and the image should be optional (Start) */}
          {/* <VStack w="100%" px="40px" align="start"> */}
          <Input
            type="number"
            placeholder="5 4 3 2 1"
            letterSpacing="10px"
            textAlign="center"
            dir="rtl"
            borderWidth="1px"
            rounded="full"
            borderColor="#2F3852"
            bgColor="#41424A"
            textColor="white"
            width="350px"
            focusBorderColor="primary.300"
          />
          <Box width="350px">
            <Text className="fs--14 fw--400" mt="18px" mb="38px" ml="auto">
              Dont recieve a code?
              <Text as="span" mx="6px" cursor="pointer" color="white">
                Resend
              </Text>
            </Text>
          </Box>

          {/* This box should be converted to the common component for the input box and the image should be optional (Start) */}
          <ButtonGroup
            w="100%"
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Button
              onClick={() => {
                setstepCount(2)
              }}
              width="350px"
              className="fs--14 fw--300 "
              rounded="23px"
              mb="16px"
            >
              Submit
            </Button>
            <Button
              cursor="pointer"
              width="350px"
              rounded="23px"
              className="fs--14 fw--400 btnScandaryMain"
              onClick={() => {
                setstepCount(stepCount - 1)
              }}
            >
              Back
            </Button>
          </ButtonGroup>
          {/* </VStack> */}
        </Flex>
      ) : (
        <>
          <Heading
            className="fs--36 fw--600"
            textTransform="capitalize"
            color="white"
            mb="49px"
          >
            Create New password
          </Heading>{' '}
          <VStack gap="30px">
            <VStack w="350px" gap="16px">
              {/* This box should be converted to the common component for the input box and the image should be optional (Start) */}
              <CustomInput type="password" placeholder="Password" />{' '}
              <CustomInput type="password" placeholder="Confirm Password" />
            </VStack>
            <VStack w="350px" gap="16px">
              <Button
                onClick={gotoLogin}
                width="100%"
                className="fs--14 fw--300 "
                rounded="full"
              >
                Submit
              </Button>
              <Button
                w="100%"
                rounded="full"
                onClick={() => {
                  setstepCount(stepCount - 1)
                }}
                className="fs--14 fw--400 btnScandaryMain"
              >
                Back
              </Button>
            </VStack>
          </VStack>
        </>
      )}
    </Stack>
  )
}
