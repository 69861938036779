import React, { useMemo } from 'react'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Flex,
  HStack,
  IconButton,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Switch,
  Text,
  useDisclosure,
} from '@chakra-ui/react'
import MainLayout from 'layout'
import DataTable from 'components/table'
import { UserManagement } from 'utils/type/userManagement.type'
import { createColumnHelper } from '@tanstack/react-table'
import { userManagement } from 'utils/data/userManagement.data'
import { MdDeleteSweep } from 'react-icons/md'
import { TbEdit } from 'react-icons/tb'
import { CheckMark } from 'assets/icons/login'
import ActionButtonAndHeading from 'components/actionButtonAndHeading'
import Header from 'components/header'
import GradientDividers from 'components/GradientDividers'
import CustomInput from 'components/customInput'
import Pagination from 'components/pagination'

const columnHelper = createColumnHelper<UserManagement>()

export default function AdminUserManagement() {
  const {
    isOpen: isOpenDeleteModal,
    onOpen: onOpendeleteModal,
    onClose: onCloseDeleteModal,
  } = useDisclosure()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const userManagementCol = useMemo(
    () => userColunms(onOpendeleteModal, onOpen),
    [],
  )
  return (
    <MainLayout>
      <>
        <Header />
        <ActionButtonAndHeading
          buttonName="Add Admin"
          onOpen={onOpen}
          headingWhite="User"
          headingPurple="Management"
        />
        <GradientDividers />
        <DeleteAdminModal
          isOpenDeleteModal={isOpenDeleteModal}
          onCloseDeleteModal={onCloseDeleteModal}
        />
        <AddAdminModal isOpen={isOpen} onClose={onClose} />
        <DataTable columns={userManagementCol} data={userManagement} />
        <Pagination />
      </>
    </MainLayout>
  )
}
interface Prop {
  isOpenDeleteModal: boolean
  onCloseDeleteModal: () => void
}
function DeleteAdminModal({ isOpenDeleteModal, onCloseDeleteModal }: Prop) {
  return (
    <Modal isOpen={isOpenDeleteModal} onClose={onCloseDeleteModal} isCentered>
      <ModalOverlay />
      <ModalContent bg="cardbg" border="2px solid #232C47">
        <ModalCloseButton
          right="90%"
          bg="primary.500"
          // bgColor="primary"
          color="white"
          rounded="50%"
        />

        <ModalBody mt="3rem">
          <Box textAlign="center">
            <CheckMark />
            <Text color="white" mt="1.5rem">
              Delete Admin
            </Text>
          </Box>
          <Text className="fs--16" color="white" dir="rtl" mt="28px">
            Are you sure you want to delete admin &quot;NFT Fan Token&quot; ?
          </Text>
        </ModalBody>

        <ModalFooter justifyContent="start" gap="7px">
          <Button fontSize="14px" fontWeight="400" w="117px" rounded="25px">
            Delete
          </Button>
          <Button
            colorScheme="secondary"
            fontSize="14px"
            w="117px"
            fontWeight="400"
            rounded="25px"
            onClick={onCloseDeleteModal}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
interface Props {
  isOpen: boolean
  onClose: () => void
}
function AddAdminModal({ isOpen, onClose }: Props) {
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="cardbg" border="2px solid #232C47">
        <ModalCloseButton
          right="90%"
          bg="primary.500"
          // bgColor="primary"
          color="white"
          rounded="50%"
        />
        <ModalHeader textAlign="end" textColor="white" className="fs--16">
          Add Admin
        </ModalHeader>
        <ModalBody>
          <HStack align="center" gap="7px">
            <CustomInput type="text" placeholder="name" />
            <CustomInput type="tel" placeholder="Phone" />
          </HStack>
          <HStack align="center" mt="12px" gap="7px">
            <CustomInput type="email" placeholder="email" />
            <CustomInput type="password" placeholder="password" />
          </HStack>
          <HStack justify="space-between" mt="1.6rem">
            <Switch />{' '}
            <Text color="white" className="fs--16">
              Is active
            </Text>
          </HStack>
          <HStack justify="space-between" mt="1rem">
            <Checkbox></Checkbox>
            <Text color="white" className="fs--16">
              Allow user for social post
            </Text>
          </HStack>
        </ModalBody>

        <ModalFooter justifyContent="start" gap="7px" mt="1.4rem">
          <Button fontSize="14px" fontWeight="400" w="117px" rounded="25px">
            Add
          </Button>
          <Button
            colorScheme="secondary"
            fontSize="14px"
            w="117px"
            fontWeight="400"
            rounded="25px"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

const userColunms = (onOpendeleteModal: () => void, onOpen: () => void) => [
  columnHelper.accessor('name', {
    cell: info => (
      <Flex alignItems="center" gap="15px" mr="-25px" className="fs--14">
        <Avatar
          // border="2px solid yellow"
          name="Christian Nwamba"
          src={info.getValue()[1]}
          className="gradient-border-mask"
        />{' '}
        <Text color="text">{info.getValue()[0]}</Text>
      </Flex>
    ),
    header: 'Admin Name',
  }),
  columnHelper.accessor('email', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info.getValue()}
      </Text>
    ),
    header: 'Email',
  }),
  columnHelper.accessor('phone', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info?.getValue()}
      </Text>
    ),
    header: 'Phone',
  }),
  columnHelper.accessor('isActive', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info?.getValue()}
      </Text>
    ),

    header: 'Is Active',
  }),
  columnHelper.accessor('action', {
    cell: () => (
      <Box mr="-25px">
        <IconButton
          onClick={onOpen}
          aria-label="Search database"
          bg="#FFFFFF1A"
          icon={
            <TbEdit fontSize="20px" style={{ fill: 'url(#blue-gradient)' }} />
          }
          color="linear-gradient(90deg, #64647A, #5B4582)"
        />{' '}
        <IconButton
          onClick={onOpendeleteModal}
          bg="#FFFFFF1A"
          aria-label="Search database"
          icon={
            <MdDeleteSweep
              fontSize="20px"
              style={{ fill: 'url(#blue-gradient)' }}
            />
          }
          mr="5px"
        />
      </Box>
    ),
    header: 'Actions',
  }),
]
