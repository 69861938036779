import React from 'react'
import {
  HStack,
  Flex,
  Avatar,
  IconButton,
  InputGroup,
  InputRightElement,
  Input,
} from '@chakra-ui/react'
import avatar3 from 'assets/images/avatar2.png'
import { useNavigate } from 'react-router-dom'
import { BiSearch } from 'react-icons/bi'
import { Cowbell, Settings } from 'assets/icons'
export default function Header() {
  const navigate = useNavigate()
  return (
    <Flex justify="space-between" mb="33px" position="sticky">
      <HStack spacing={3}>
        <Avatar name="Dan Abrahmov" boxSize="40px" src={avatar3} />
        <IconButton
          isRound
          bg="#FFFFFF1A"
          aria-label="Search database"
          icon={<Cowbell />}
          _hover={{}}
        />
        <IconButton
          isRound
          bg="#FFFFFF1A"
          aria-label="Search database"
          icon={<Settings />}
          onClick={() => navigate('/profile-settings')}
          _hover={{}}
        />

        <svg width="0" height="0">
          <linearGradient
            id="blue-gradient"
            x1="100%"
            y1="100%"
            x2="0%"
            y2="0%"
          >
            <stop stopColor="#7a6ded" offset="0%" />
            <stop stopColor="#591885" offset="100%" />
          </linearGradient>
        </svg>
      </HStack>
      <InputGroup w="770px" dir="rtl">
        <Input
          px="2rem"
          type="tel"
          placeholder="Search"
          rounded="full"
          borderWidth="1px"
          borderColor="#2F3852"
          bgColor="#41424A"
          textColor="white"
          focusBorderColor="primary.300"
        />
        <InputRightElement pointerEvents="none">
          <BiSearch fontSize="21px" style={{ fill: 'url(#blue-gradient)' }} />
        </InputRightElement>
      </InputGroup>
    </Flex>
  )
}
