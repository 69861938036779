import React, { useState } from 'react'
import {
  Box,
  Button,
  HStack,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Text,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import MainLayout from 'layout'
import { Payments } from 'utils/type/userManagement.type'
import { createColumnHelper } from '@tanstack/react-table'
import {
  AiOutlineDownload,
  AiOutlineEye,
  AiOutlinePrinter,
} from 'react-icons/ai'
import DataTable from 'components/table'
import { payments } from 'utils/data/userManagement.data'
import ActionButtonAndHeading from 'components/actionButtonAndHeading'
import Header from 'components/header'
import GradientDividers from 'components/GradientDividers'
import CustomInput from 'components/customInput'
import Pagination from 'components/pagination'
import { FileUploader } from 'assets/icons'
const columnHelper = createColumnHelper<Payments>()
// const {
//   isOpen: isOpenDeleteModal,
//   onOpen: OnOpendeleteModal,
//   onClose: onCloseDeleteModal,
// } = useDisclosure()
const paymentCol = [
  columnHelper.accessor('invoiceID', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info.getValue()}
      </Text>
    ),
    header: 'Invoice ID',
  }),
  columnHelper.accessor('date', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info?.getValue()}
      </Text>
    ),
    header: 'Invoice Date',
  }),
  columnHelper.accessor('amount', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info?.getValue()} SAR
      </Text>
    ),

    header: 'Invoice Amount',
  }),
  columnHelper.accessor('action', {
    cell: () => (
      <Box mr="-25px">
        <IconButton
          bg="#FFFFFF1A"
          aria-label="Search database"
          icon={
            <AiOutlinePrinter
              fontSize="20px"
              style={{ fill: 'url(#blue-gradient)' }}
            />
          }
        />
        <IconButton
          aria-label="Search database"
          mx="5px"
          bg="#FFFFFF1A"
          icon={
            <AiOutlineDownload
              fontSize="20px"
              style={{ fill: 'url(#blue-gradient)' }}
            />
          }
        />
        <IconButton
          // onClick={() => OnOpendeleteModal()}
          aria-label="Search database"
          bg="#FFFFFF1A"
          icon={
            <AiOutlineEye
              fontSize="20px"
              style={{ fill: 'url(#blue-gradient)' }}
            />
          }
        />
      </Box>
    ),
    header: 'Actions',
  }),
]
export default function AdminPayments() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <MainLayout>
      <>
        <Header />
        <ActionButtonAndHeading
          buttonName="Complete KYC"
          onOpen={onOpen}
          headingWhite="Payments"
        />
        <GradientDividers />
        <KYCModal isOpen={isOpen} onClose={onClose} />
        <DataTable columns={paymentCol} data={payments} />
        <Pagination />
      </>
    </MainLayout>
  )
}
interface Props {
  isOpen: boolean
  onClose: () => void
}
function KYCModal({ isOpen, onClose }: Props) {
  const [inputRef, setInputRef] = useState<HTMLInputElement | null>(null)
  const [file, setFile] = useState<string[]>([])
  const [filename, setFileName] = useState<string>()
  const handleClick = () => {
    if (inputRef) {
      inputRef.click()
    }
  }
  const handleChange = async (files: FileList | null) => {
    if (files) {
      setFileName(files[0].name)
      const image = URL.createObjectURL(files[0])
      setFile([image])
    }
  }
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="cardbg" border="2px solid #232C47">
        <ModalCloseButton
          right="90%"
          bg="primary.500"
          // bgColor="primary"
          color="white"
          rounded="50%"
        />

        <ModalBody mt="4rem">
          <Text color="white" textAlign="end">
            Kindly provide your bank account IBAN number where you want to
            receive the payments. Thanks!
          </Text>
          <HStack mt="2rem">
            <Box
              w="204px"
              py="1.9rem"
              bg="teal"
              rounded="14px"
              textAlign="center"
              bgColor="#41424A"
              onClick={handleClick}
            >
              {' '}
              <Input
                type="file"
                border="0"
                ref={input => setInputRef(input)}
                onChange={e => handleChange(e.target.files)}
                display="none"
              />
              <FileUploader />
              {file.length !== 0 ? (
                <Text>{filename}</Text>
              ) : (
                <Text color="white" className="fs--12">
                  Upload Bank Statement
                </Text>
              )}
            </Box>
            <VStack align="start">
              {' '}
              <CustomInput type="text" placeholder="IBAN" />
              <CustomInput type="text" placeholder="Account holder" />
              <CustomInput type="text" placeholder="Bank name" />
            </VStack>
          </HStack>
        </ModalBody>

        <ModalFooter justifyContent="start" gap="7px" mt="1.4rem">
          <Button fontSize="14px" fontWeight="400" w="117px" rounded="25px">
            Save
          </Button>
          <Button
            colorScheme="secondary"
            fontSize="14px"
            w="117px"
            fontWeight="400"
            rounded="25px"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
