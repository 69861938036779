import React, { useRef } from 'react'
import MainLayout from 'layout'
import {
  Box,
  Flex,
  Text,
  Tag,
  ButtonGroup,
  Button,
  Image,
  HStack,
  Avatar,
  VStack,
} from '@chakra-ui/react'
import Nft from 'assets/images/nft.png'
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons'
import Slider from 'react-slick'
import { BiBarChart } from 'react-icons/bi'
import { BsCoin } from 'react-icons/bs'
import { MdGroups } from 'react-icons/md'
import Header from 'components/header'
import GradientDividers from 'components/GradientDividers'
import { dashboardCard } from 'utils/data/dashboard.data'
import { Star } from 'assets/icons'
export default function AdminDashboard() {
  const sliderRef = useRef<Slider>(null)
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    initialSlide: 0,
    //
    variableWidth: true, // enable variable width,
    responsive: [
      {
        breakpoint: 2560,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 1440,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  }

  const goToNextSlide = () => {
    if (sliderRef.current) {
      sliderRef?.current?.slickNext()
    }
  }
  const goTopreviousSlide = () => {
    if (sliderRef.current) {
      sliderRef?.current.slickPrev()
    }
  }

  return (
    <MainLayout>
      <>
        <Header />
        <Flex
          mt="20px"
          pl="70px"
          alignItems="end"
          justifyContent="space-between"
        >
          <Flex
            p="20px"
            rounded="12px"
            bg="primary.400"
            maxW="418px"
            w="100%"
            h="200px"
            gap="12px"
          >
            <Box
              // position="absolute"
              maxW="215px"
              h="247px"
              rounded="6px"
              boxShadow="0px 0px 15px #4F81FD2B"
              p="14px"
              bg="#1F2436"
            >
              <Image
                src={Nft}

                // w="100%"
                // h="158px"
              />
              <Flex
                mt="3px"
                mb="9px"
                alignItems="center"
                justifyContent="space-between"
              >
                <Tag
                  className="fs--10"
                  variant="solid"
                  bg="#4f81fd4d"
                  color="#4F81FD"
                  borderRadius="lg"
                >
                  Polygon
                </Tag>
                <Text className="fs--14 fw--600" as="span" color="text">
                  AXEL WITSEL
                </Text>
              </Flex>
              <Flex
                className="fs--14 fw--500"
                alignItems="center"
                justifyContent="space-between"
              >
                <Text color="white">325,652,54 SAR</Text>
                <Text color="text" className="fw--500">
                  Total
                </Text>
              </Flex>
            </Box>
            <VStack justify="space-between" pt="10px" align="end">
              <Text color="black" className="fs--20 fw--800">
                The number of NFTs listed
              </Text>
              <Text className="fs--36 fw--700" color="white">
                345
              </Text>
            </VStack>
          </Flex>
          <Flex>
            <Box
              background="linear-gradient(120deg, rgb(79, 129, 253) -30%, rgba(19, 22, 35,1)20%,rgba(19, 22, 35,1) 50%,rgba(19, 22, 35,1) 70%,rgba(19, 22, 35,1) 80%, rgb(79, 129, 253) 120%)"
              // maxW="188px"
              // w="100%"
              rounded="12px"
              p="18px"
            >
              <MdGroups
                style={{
                  borderRadius: '8px',

                  padding: '9px',
                  backgroundColor: '#4F81FD',

                  color: 'black',
                  marginBottom: '10px',
                }}
                fontSize="37px"
              />

              <Text className="fs--16" color="white" mb="10px">
                No of Owners
              </Text>
              <Text
                className="fs--18 fw--800"
                // variant="primary"
                bg="primary.600"
                // bg="primary.500"
                backgroundClip="text"
                // backgroundClip="text"
              >
                627
              </Text>
            </Box>
            <Box
              background="linear-gradient(120deg, rgba(192,61,253,1) -30%, rgba(19, 22, 35,1)20%,rgba(19, 22, 35,1) 50%,rgba(19, 22, 35,1) 70%,rgba(19, 22, 35,1) 80%, rgba(192,61,253,1) 120%)"
              ml="-15px"
              // maxW="188px"
              // w="100%"
              rounded="12px"
              p="18px"
            >
              <BiBarChart
                style={{
                  borderRadius: '8px',
                  fontSize: '37px',
                  padding: '9px',
                  backgroundColor: '#C03DFD',
                  marginBottom: '10px',
                  color: 'black',
                }}
              />
              <Text className="fs--16" color="white" mb="10px">
                Trading Volume
              </Text>
              <Text
                className="fs--18 fw--600"
                bg="primary.600"
                // bg="primary.500"
                backgroundClip="text"
                // variant="primary"
              >
                345
              </Text>
            </Box>
            <Box
              background="linear-gradient(120deg, rgb(79, 129, 253) -30%, rgba(19, 22, 35,1)20%,rgba(19, 22, 35,1) 50%,rgba(19, 22, 35,1) 70%,rgba(19, 22, 35,1) 80%, rgb(79, 129, 253) 120%)"
              ml="-15px"
              // maxW="188px"
              // w="100%"
              rounded="12px"
              p="18px"
            >
              <BsCoin
                style={{
                  borderRadius: '8px',
                  fontSize: '37px',
                  padding: '9px',
                  backgroundColor: '#4F81FD',

                  color: 'black',
                  marginBottom: '10px',
                }}
              />
              <Text className="fs--16" color="white" mb="10px">
                Average Price
              </Text>
              <Text
                className="fs--18 fw--800"
                bg="primary.600"
                // bg="primary.500"
                backgroundClip="text"
              >
                4625.87
                <Text className="fs--12" ml="4px" color="primary.400" as="span">
                  SAR
                </Text>
              </Text>
            </Box>
          </Flex>
        </Flex>

        <HStack align="center" justify="space-between" mt="7rem">
          <ButtonGroup ml="3rem">
            <Button onClick={goTopreviousSlide} p="0px" rounded="full">
              <ChevronLeftIcon fontSize="25px" />
            </Button>
            <Button
              onClick={goToNextSlide}
              p="0px"
              rounded="full"
              bg="#FFFFFF1A"
            >
              <ChevronRightIcon fontSize="25px" />
            </Button>
          </ButtonGroup>
          <Text className="fs--24">
            The Best Performing
            <Text as="span" color="primary.300">
              {' '}
              Group
            </Text>
          </Text>
        </HStack>
        <GradientDividers />
        <Slider ref={sliderRef} {...settings}>
          {dashboardCard.map((item, index) => {
            return (
              <Box key={index} padding="10px">
                {/* Card Images */}
                <Box
                  bg="#1F2436"
                  p="13px"
                  rounded="10px"
                  border="1px"
                  borderColor="#343A52"
                  boxShadow="0px 0px 15px #4F81FD2B"
                >
                  <HStack position="relative">
                    <Image
                      src={item?.subNft1}
                      h="77px"
                      rounded="8px"
                      objectFit="cover"
                      flex={0.25}
                    />
                    <Image
                      src={item?.mainNft}
                      h="77px"
                      rounded="8px"
                      objectFit="cover"
                      flex={0.5}
                    />
                    <Image
                      src={item?.subNft2}
                      h="77px"
                      rounded="8px"
                      objectFit="cover"
                      flex={0.25}
                    />
                    <Avatar
                      left="40%"
                      bottom="-5"
                      position="absolute"
                      className="gradient-border-mask"
                      src={item?.avatar}
                    />
                  </HStack>
                  <HStack align="center" mt="36px" justify="end">
                    <Text className="fs--14 fw--600">NFT FAN TOKENS</Text>
                    <Star />
                  </HStack>
                  <Flex my="12px">
                    <Text
                      className="fs--12 fw--400"
                      color="text"
                      textAlign="end"
                      flex={0.5}
                    >
                      Average Price
                    </Text>

                    <Text
                      className="fs--12 fw--400"
                      color="text"
                      textAlign="end"
                      flex={0.5}
                    >
                      Total
                    </Text>
                  </Flex>
                  <Flex>
                    <Text className="fs--14 fw--400" textAlign="end" flex={0.5}>
                      {item?.avgPrice} SAR
                    </Text>

                    <Text className="fs--14 fw--400" textAlign="end" flex={0.5}>
                      {item?.total}
                    </Text>
                  </Flex>
                </Box>
              </Box>
            )
          })}
        </Slider>
      </>
    </MainLayout>
  )
}
