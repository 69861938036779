import React, { useState } from 'react'
import {
  Box,
  Button,
  Card,
  Flex,
  Heading,
  HStack,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Tag,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import MainLayout from 'layout'
import ActionButtonAndHeading from 'components/actionButtonAndHeading'
import { NFTMinting } from 'utils/type/userManagement.type'
import { nFTMinting } from 'utils/data/userManagement.data'
import { FaVideo } from 'react-icons/fa'
import { MdBrokenImage } from 'react-icons/md'
import { RiVipCrownFill } from 'react-icons/ri'
import { ChevronDownIcon, ChevronLeftIcon } from '@chakra-ui/icons'
import { DefaultImage } from 'assets/icons/login'
import GradientDividers from 'components/GradientDividers'
import Header from 'components/header'
import CustomInput from 'components/customInput'
import img2 from 'assets/images/2.png'
import img3 from 'assets/images/3.png'
import img4 from 'assets/images/4.png'
import { Tick } from 'assets/icons'

export default function AdminNftMinting() {
  const { isOpen, onOpen, onClose } = useDisclosure()
  return (
    <MainLayout>
      <>
        <Header />
        <ActionButtonAndHeading buttonName="Mint NFT" onOpen={onOpen} />
        <GradientDividers />

        <Flex wrap="wrap" dir="rtl">
          {nFTMinting.map((card, index) => (
            <MintingCard card={card} key={index} />
          ))}
        </Flex>
        <MintNFTModal isOpen={isOpen} onClose={onClose} />
      </>
    </MainLayout>
  )
}

interface Prop {
  isOpen: boolean
  onClose: () => void
}
function MintNFTModal({ isOpen, onClose }: Prop) {
  const [opt, setOpt] = useState('default')
  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent className="glass-effect" bg="transparent">
        <ModalBody mt="0.5rem">
          {opt === 'default' && (
            <ModalCloseButton
              right="90%"
              bg="primary.500"
              // bgColor="primary"
              color="white"
              rounded="50%"
            />
          )}
          {(opt === 'video' || opt === 'image') && (
            <>
              <Flex justify="space-between">
                <IconButton
                  aria-label="Search database"
                  icon={<ChevronLeftIcon />}
                  isRound
                  onClick={() => setOpt('default')}
                />
                <HStack>
                  {opt === 'image' ? (
                    <DefaultImage />
                  ) : (
                    <FaVideo
                      style={{ fill: 'url(#blue-gradient)' }}
                      fontSize="35px"
                    />
                  )}
                  <Heading className="fs--16" color="white">
                    NFT Minting
                  </Heading>
                </HStack>
              </Flex>
              {/* image uploader */}
              <ImageUploaderBox />

              <CustomInput type="text" placeholder="name" />
              <HStack my="15px">
                <Menu>
                  <MenuButton
                    flex={0.5}
                    as={Button}
                    leftIcon={<ChevronDownIcon fontSize="18px" />}
                    rounded="full"
                    className="fs--12"
                    colorScheme="secondary"
                  >
                    Token Type
                  </MenuButton>
                  <MenuList bg="cardbg" dir="rtl" border="2px solid #232C47">
                    {['ERC 721', 'ERC 1155'].map(menu => (
                      <MenuItem bg="cardbg" key={menu} color="white" gap="5px">
                        {/* <GiFlowerStar style={{ fill: 'url(#blue-gradient)' }} /> */}
                        {menu}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
                <Menu>
                  <MenuButton
                    flex={0.5}
                    as={Button}
                    colorScheme="secondary"
                    leftIcon={<ChevronDownIcon fontSize="18px" />}
                    rounded="full"
                    className="fs--12"
                  >
                    Select Collection
                  </MenuButton>
                  <MenuList bg="cardbg" dir="rtl" border="2px solid #232C47">
                    {[
                      'NFT Fan Token',
                      'Top Shot Creator',
                      'Rangers Football',
                      'Road to the Super Ball',
                    ].map(menu => (
                      <MenuItem bg="cardbg" key={menu} color="white" gap="5px">
                        {/* <GiFlowerStar style={{ fill: 'url(#blue-gradient)' }} /> */}
                        {menu}
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </HStack>
              <HStack>
                <Box flex={0.5}>
                  <CustomInput type="text" placeholder="price" />
                </Box>
                <Box flex={0.5}>
                  <CustomInput type="text" placeholder="supply" />
                </Box>
              </HStack>
              <Textarea
                mt="12px"
                borderWidth="1px"
                placeholder="Description"
                textAlign="end"
                rounded="10px"
                borderColor="#2F3852"
                bgColor="#41424A"
                textColor="white"
                size="sm"
                resize="vertical"
              />
            </>
          )}
          {opt === 'default' && (
            <>
              {' '}
              <Heading className="fs--35" textAlign="center" color="white">
                NFT Minting
              </Heading>
              <VStack justify="center" gap="13px" my="2rem">
                <Box
                  cursor="pointer"
                  w="318px"
                  bg="primary.500"
                  rounded="8px"
                  textAlign="center"
                  py="1rem"
                  onClick={() => setOpt('video')}
                >
                  <FaVideo color="white" fontSize="35px" />
                  <Text className="fs--12" color="white">
                    Video
                  </Text>
                </Box>
                <Box
                  cursor="pointer"
                  w="318px"
                  bg="secondary.500"
                  rounded="8px"
                  textAlign="center"
                  py="1rem"
                  border="1px"
                  borderColor="#707070"
                  onClick={() => setOpt('image')}
                >
                  <MdBrokenImage color="white" fontSize="35px" />
                  <Text className="fs--12" color="white">
                    Image
                  </Text>
                </Box>
                <Box
                  cursor="pointer"
                  w="318px"
                  bg="secondary.500"
                  rounded="8px"
                  textAlign="center"
                  py="1rem"
                  border="1px"
                  borderColor="#707070"
                  onClick={() => setOpt('premium')}
                >
                  <RiVipCrownFill color="white" fontSize="35px" />
                  <Text className="fs--12" color="white">
                    Premium Minting
                  </Text>
                </Box>
              </VStack>
            </>
          )}
          {opt === 'premium' && (
            <Flex justify="space-between" align="center">
              <IconButton
                aria-label="Search database"
                icon={<ChevronLeftIcon />}
                isRound
                onClick={() => setOpt('default')}
              />

              <Heading className="fs--16" color="white">
                Premium Minting
              </Heading>
            </Flex>
          )}
          {opt === 'premium' && (
            <>
              <PremiumMinting />
              <ImageUploaderBox />
            </>
          )}
          {opt === 'submit' && (
            <>
              <Box textAlign="center">
                <Tick />
                <Text
                  className="fs--16"
                  color="white"
                  textAlign="end"
                  mt="28px"
                >
                  Thanks for submitting your details it has been well received
                  we will get back to you via email.
                </Text>{' '}
                <Button
                  rounded="full"
                  mt="1rem"
                  w="117px"
                  className="fs--14"
                  onClick={onClose}
                >
                  Done
                </Button>
              </Box>
            </>
          )}
        </ModalBody>

        <ModalFooter justifyContent="start" gap="7px">
          {(opt === 'video' || opt === 'image' || opt === 'premium') && (
            <>
              {' '}
              <Button
                fontSize="14px"
                fontWeight="400"
                w="117px"
                rounded="25px"
                onClick={() => {
                  setOpt('submit')
                }}
              >
                {opt === 'video' || opt === 'image' ? 'Create' : 'Submit'}
              </Button>
              <Button
                colorScheme="secondary"
                fontSize="14px"
                w="117px"
                fontWeight="400"
                rounded="25px"
                onClick={onClose}
              >
                Cancel
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
interface IMintCard {
  card: NFTMinting
}
function MintingCard({ card }: IMintCard) {
  return (
    <Card
      bg="cardbg"
      maxW="240px"
      p="13px"
      m="8px"
      border="2px solid #232C47"
      // boxShadow="dark-lg blue"
      boxShadow="0px 0px 15px #4F81FD2B"
      // boxShadow="0 0 5px #072873"
      rounded="md"
    >
      <Image
        borderRadius="md"
        w="213px"
        h="156px"
        objectFit="cover"
        src={card?.image}
        alt="Dan Abramov"
      />
      <HStack my="13px" justify="space-between">
        <Heading className="fs--12" color="#99ACD2">
          {card?.name}
        </Heading>{' '}
        <Tag
          size="lg"
          className="fs--10"
          variant="solid"
          colorScheme="blue"
          borderRadius="lg"
          opacity="0.5"
        >
          Polygon
        </Tag>
      </HStack>
      <HStack justify="space-between">
        {' '}
        <Text className="fs--12" color="#99ACD2">
          Total
        </Text>
        <Text className="fs--14" color="white">
          {card?.price}{' '}
          <Text as="span" fontSize="8px">
            SAR
          </Text>
        </Text>
      </HStack>
    </Card>
  )
}
function ImageUploaderBox() {
  return (
    <Box
      color="white"
      textAlign="center"
      py="2.4rem"
      bg="#41424A"
      my="16px"
      rounded="10px"
      border="1px"
      borderStyle="dashed"
    >
      <Text className="fs--12">
        Drag & drop image or{' '}
        <Text
          as="span"
          textDecoration="underline"
          color="primary.400"
          cursor="pointer"
        >
          browse
        </Text>
      </Text>
    </Box>
  )
}
function PremiumMinting() {
  const randomPics = [img2, img3, img4]
  return (
    <>
      <Text color="#99ACD2" textAlign="end">
        Sample NFT&apos;s
      </Text>
      <HStack my="1rem">
        {randomPics.map((img, index) => (
          <Image
            key={index}
            w="131px"
            h="95px"
            rounded="4px"
            objectFit="cover"
            src={img}
            alt="Dan Abramov"
          />
        ))}
      </HStack>
      <HStack>
        <Box flex={0.5}>
          <CustomInput type="email" placeholder="Contact email" />
        </Box>
        <Box flex={0.5}>
          <CustomInput type="text" placeholder="name" />
        </Box>
      </HStack>
      <HStack my="1rem">
        <Menu>
          <MenuButton
            w="50%"
            as={Button}
            colorScheme="secondary"
            leftIcon={<ChevronDownIcon fontSize="18px" />}
            rounded="full"
            className="fs--12"
          >
            NFT category
          </MenuButton>
          <MenuList bg="cardbg" dir="rtl" border="2px solid #232C47">
            {['ERC 721', 'ERC 1155'].map(menu => (
              <MenuItem bg="cardbg" key={menu} color="white" gap="5px">
                {/* <GiFlowerStar style={{ fill: 'url(#blue-gradient)' }} /> */}
                {menu}
              </MenuItem>
            ))}
          </MenuList>
        </Menu>

        <Box w="50%">
          <CustomInput type="tel" placeholder="Phone number" />
        </Box>
      </HStack>
      <CustomInput type="text" placeholder="Add some keywords" />
      <Textarea
        mt="1rem"
        focusBorderColor="primary.300"
        borderWidth="1px"
        placeholder="Tell us what you need"
        textAlign="end"
        rounded="10px"
        borderColor="#2F3852"
        bgColor="#41424A"
        textColor="white"
        size="sm"
        resize="vertical"
      />
    </>
  )
}
