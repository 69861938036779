import React from 'react'
import { IRouteTabs } from 'utils/type/routeTabs.type'
import { AiOutlineHome } from 'react-icons/ai'
import { RxDashboard } from 'react-icons/rx'
import { Nftminting, Payments, ProjectMangement } from 'assets/icons'
import { BiEdit } from 'react-icons/bi'

export const routeTabs: IRouteTabs[] = [
  {
    icon: <AiOutlineHome fontSize="15px" />,
    name: '  Dashboard',
    path: 'admin-dashboard',
  },
  {
    icon: <RxDashboard fontSize="15px" />,
    name: 'Collections',
    path: 'admin-collection',
  },
  {
    icon: <Nftminting color="#ffffff" />,
    name: 'NFT Minting',
    path: 'admin-nftminting',
  },
  {
    icon: <BiEdit fontSize="15px" />,
    name: 'Social Post',
    path: 'admin-socialpost',
  },
  {
    icon: <ProjectMangement color="#ffffff" />,
    name: 'User Managemant',
    path: 'admin-usermanagement',
  },
  {
    icon: <Payments color="#ffffff" />,
    name: 'Payments',
    path: 'admin-payments',
  },
]
