import React from 'react'
import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  Textarea,
  useDisclosure,
  VStack,
} from '@chakra-ui/react'
import MainLayout from 'layout'
import DataTable from 'components/table'
import { createColumnHelper } from '@tanstack/react-table'
import { ColletionTable } from 'utils/type/collection.type'
import { data } from 'utils/data/collection.data'
import { AiFillCamera } from 'react-icons/ai'
import { MdFileUpload } from 'react-icons/md'
import { DefaultImage } from 'assets/icons/login'
import ActionButtonAndHeading from 'components/actionButtonAndHeading'
import Header from 'components/header'
import GradientDividers from 'components/GradientDividers'
import CustomInput from 'components/customInput'
import Pagination from 'components/pagination'
const columnHelper = createColumnHelper<ColletionTable>()

const columns2 = [
  columnHelper.accessor('collectionName', {
    cell: info => (
      <Flex alignItems="center" gap="15px" mr="-25px" className="fs--14">
        <Avatar
          // border="2px solid yellow"
          name="Christian Nwamba"
          src={info.getValue()[0]}
          className="gradient-border-mask"
        />{' '}
        <Text color="text">{info.getValue()[1]}</Text>
      </Flex>
    ),
    header: 'Collection Name',
  }),
  columnHelper.accessor('NoOfNfts', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info.getValue()}
      </Text>
    ),
    header: 'No. Of Nfts',
  }),
  columnHelper.accessor('totalSold', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info?.getValue()}
      </Text>
    ),
    header: 'Total Sold',
  }),
  columnHelper.accessor('owners', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info?.getValue()}
      </Text>
    ),

    header: 'Owners',
  }),

  columnHelper.accessor('totalVolume', {
    cell: info => (
      <Text color="text" mr="-25px" className="fs--14">
        {info?.getValue()} SAR
      </Text>
    ),
    header: 'Total Volume',
  }),
]

export default function AdminCollection() {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <MainLayout>
      <>
        <Header />
        <ActionButtonAndHeading
          buttonName="Create New"
          headingWhite="NFT"
          headingPurple="Collections"
          onOpen={onOpen}
        />
        <GradientDividers />
        <CollectionModal isOpen={isOpen} onClose={onClose} />
        <DataTable columns={columns2} data={data} />
        <Pagination />
      </>
    </MainLayout>
  )
}
interface Props {
  isOpen: boolean
  onClose: () => void
}
function CollectionModal({ isOpen, onClose }: Props) {
  const [inputRef, setInputRef] = React.useState<HTMLInputElement | null>(null)
  const [file, setFile] = React.useState<string[]>([])
  const handleClick = () => {
    if (inputRef) {
      inputRef.click()
    }
  }
  const handleChange = async (files: FileList | null) => {
    if (files) {
      const image = URL.createObjectURL(files[0])
      setFile([image])
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="cardbg" border="2px solid #232C47">
        <ModalHeader textAlign="end" textColor="white" className="fs--16">
          Create a collection
        </ModalHeader>
        <ModalBody px="0">
          <Box bg="#41424A" h="118px" position="relative">
            <VStack>
              {file.length !== 0 ? (
                <Image src={file[0]} alt="Preview" w="100%" h="118px" />
              ) : (
                <>
                  <Box mt="1rem">
                    <DefaultImage />
                  </Box>
                  <Button
                    className="fs--16 fw--500"
                    // mt="0.5rem"
                    mx="auto"
                    variant="ghost"
                    color="white"
                    onClick={handleClick}
                  >
                    {' '}
                    <Input
                      type="file"
                      border="0"
                      ref={input => setInputRef(input)}
                      onChange={e => handleChange(e.target.files)}
                      display="none"
                    />
                    <IconButton
                      aria-label="Call Segun"
                      mr="5px"
                      icon={<MdFileUpload fontSize="25px" />}
                    />
                    upload cover Image
                  </Button>
                </>
              )}
            </VStack>
            <Flex
              rounded="7px"
              alignItems="center"
              justifyContent="end"
              mt="2px"
              mr="18px"
            >
              <Text
                className="fs--14 fw--600"
                mr="10px"
                color="white"
                as="span"
              >
                Logo
              </Text>{' '}
              <Avatar
                size="lg"
                bgColor="#1C1D2C"
                border="1px solid gray"
                icon={<DefaultImage />}
              >
                <AvatarBadge
                  as={'image'}
                  bg="primary.500"
                  border="0"
                  left="0"
                  bottom="1px"
                  boxSize="20px"
                >
                  <AiFillCamera fontSize="12px" color="black" />
                </AvatarBadge>
              </Avatar>
            </Flex>
          </Box>
          <Box px="1rem" mt="3.4rem">
            <CustomInput type="tel" placeholder="Collection name" />
            <Box mt="12px">
              {' '}
              <CustomInput type="tel" placeholder="Royalty percentage" />
            </Box>
            <Textarea
              mt="12px"
              borderWidth="1px"
              placeholder="Description"
              textAlign="end"
              rounded="10px"
              borderColor="#2F3852"
              bgColor="#41424A"
              textColor="white"
              size="sm"
              resize="vertical"
            />
          </Box>
        </ModalBody>

        <ModalFooter justifyContent="start" gap="7px">
          <Button fontSize="14px" fontWeight="400" w="117px" rounded="25px">
            Create
          </Button>
          <Button
            colorScheme="secondary"
            fontSize="14px"
            w="117px"
            fontWeight="400"
            rounded="25px"
            onClick={onClose}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}
