import {
  NFTMinting,
  Payments,
  UserManagement,
} from 'utils/type/userManagement.type'
import img1 from 'assets/images/1.png'
import img2 from 'assets/images/2.png'
import img3 from 'assets/images/3.png'
import img4 from 'assets/images/4.png'
import img5 from 'assets/images/5.png'
import img6 from 'assets/images/6.png'
import avatar3 from 'assets/images/avatar3.png'
import avatar2 from 'assets/images/avatar2.png'
export const userManagement: UserManagement[] = [
  {
    isActive: 'active',
    phone: '+966 256 3251',
    email: 'user@gmail.com',
    name: ['Christian Nwamba', avatar3],
  },
  {
    isActive: 'active',
    phone: '+966 256 3251',
    email: 'user@gmail.com',
    name: ['Christian Nwamba', avatar2],
  },
  {
    isActive: 'active',
    phone: '+966 256 3251',
    email: 'user@gmail.com',
    name: ['Christian Nwamba', avatar2],
  },
  {
    isActive: 'active',
    phone: '+966 256 3251',
    email: 'user@gmail.com',
    name: ['Christian Nwamba', avatar3],
  },
  {
    isActive: 'active',
    phone: '+966 256 3251',
    email: 'user@gmail.com',
    name: ['Christian Nwamba', avatar2],
  },
  {
    isActive: 'active',
    phone: '+966 256 3251',
    email: 'user@gmail.com',
    name: ['Christian Nwamba', avatar3],
  },
]

export const payments: Payments[] = [
  {
    amount: 2477,
    date: '19-05-2022',
    invoiceID: 'INV-S22004306',
  },
  {
    amount: 32324,
    date: '19-05-2022',
    invoiceID: 'INV-S224306',
  },
  {
    amount: 32324,
    date: '19-08-2022',
    invoiceID: 'INV-S226434',
  },
  {
    amount: 2477,
    date: '19-05-2022',
    invoiceID: 'INV-S22004306',
  },
  {
    amount: 32324,
    date: '19-05-2022',
    invoiceID: 'INV-S224306',
  },

  {
    amount: 32324,
    date: '19-05-2022',
    invoiceID: 'INV-S224306',
  },
]

export const nFTMinting: NFTMinting[] = [
  {
    image: img1,
    name: 'Tioluwani',
    price: 3454334,
  },
  {
    image: img5,
    name: 'Abrahmov',
    price: 3454334,
  },
  {
    image: img1,
    name: 'Kent ',
    price: 3454334,
  },
  {
    image: img2,
    name: 'Florence',
    price: 3454334,
  },
  {
    image: img3,
    name: 'Otemuyiwa',
    price: 3454334,
  },
  {
    image: img4,
    name: 'Nwamba',
    price: 3454334,
  },
  {
    image: img5,
    name: 'Adebayo',
    price: 3454334,
  },
  {
    image: img6,
    name: 'Abrahmov',
    price: 3454334,
  },
]
