import { Divider } from '@chakra-ui/react'
import React from 'react'
export default function GradientDividers() {
  return (
    <>
      <Divider
        mt={4}
        borderBottom="0"
        style={{
          height: '2px',

          backgroundImage: 'linear-gradient(90deg, #C03DFD, #4F81FD)',
          clipPath: 'polygon(25% 0%, 100% 0%, 100% 100%, 25% 100%, 0% 50%)',
        }}
      />
      <Divider
        mt={1}
        mb={4}
        borderBottom="0"
        style={{
          height: '2px',
          backgroundImage: 'linear-gradient(90deg, #4F81FD, #C03DFD)',
          clipPath: 'polygon(0% 0%, 75% 0%, 100% 50%, 75% 100%, 0% 100%)',
        }}
      />
    </>
  )
}
