import React from 'react'
import { Stack } from '@chakra-ui/react'
import { Routes, Route, Navigate } from 'react-router-dom'
import Authentication from 'views/login'
import AdminDashboard from 'views/dashboard'
import AdminNftMinting from 'views/nftMinting'
import AdminSocialPost from 'views/socialPost'
import AdminUserManagement from 'views/userManagement'
import AdminPayments from 'views/payments'
import AdminCollection from 'views/collection'
import AdminSettings from 'views/settings'
const routes = [
  {
    path: '/',
    Component: Authentication,
  },
  {
    path: '/admin-dashboard',
    Component: AdminDashboard,
  },
  {
    path: '/admin-nftminting',
    Component: AdminNftMinting,
  },
  {
    path: '/admin-socialpost',
    Component: AdminSocialPost,
  },
  {
    path: '/admin-usermanagement',
    Component: AdminUserManagement,
  },
  {
    path: '/admin-payments',
    Component: AdminPayments,
  },
  {
    path: '/admin-collection',
    Component: AdminCollection,
  },
  {
    path: '/profile-settings',
    Component: AdminSettings,
  },
]
export default function AppRoutes() {
  return (
    <Stack dir="rtl">
      <Routes>
        {routes?.map(({ path, Component }) => (
          <Route key={path} element={<Component />} path={path} />
        ))}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Stack>
  )
}
