import React from 'react'
import {
  Flex,
  Text,
  Button,
  Image,
  Card,
  CardHeader,
  Avatar,
  Box,
  Heading,
  CardBody,
  CardFooter,
  Divider,
  Input,
  Textarea,
  HStack,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react'
// import sideBarCard from 'assets/images/sidebar/sideBarCard.svg'
import MainLayout from 'layout'
import { BsThreeDotsVertical } from 'react-icons/bs'
import Header from 'components/header'
import { RiShareForwardFill } from 'react-icons/ri'
import { FaCommentDots } from 'react-icons/fa'
import { AiFillLike } from 'react-icons/ai'
import { IoSend } from 'react-icons/io5'
import avatar3 from 'assets/images/avatar3.png'
import avatar2 from 'assets/images/avatar2.png'

export default function AdminSocialPost() {
  return (
    <MainLayout>
      <>
        <Header />
        <Flex gap="14px" justify="end">
          <Box flex={0.3} maxW="365px">
            {/* Time line */}
            <Box p="16px" rounded="12px" bg="primary.100">
              <Text className="fs--16" textAlign="end">
                Timeline
              </Text>
              <Divider mt="6px" mb="12px" />
              {[0, 1, 2].map(item => {
                return (
                  <Flex
                    key={item}
                    mb="18px"
                    justifyContent="end"
                    alignItems="center"
                    w="100%"
                  >
                    {item === 0 ? (
                      <Text
                        w="85%"
                        textAlign="end"
                        bg="primary.99"
                        as="span"
                        className="fs--12"
                        opacity={1}
                        background="linear-gradient(270deg, #7AEFFF -20.4%, #99ACD226 22.91%)"
                        backdropFilter="blur(30px)"
                        rounded="2px"
                        borderRight="2px solid #7AEFFF"
                        p="14px"
                      >
                        User A liked the post
                      </Text>
                    ) : item === 1 ? (
                      <Text
                        w="85%"
                        textAlign="end"
                        bg="primary.99"
                        as="span"
                        className="fs--12"
                        opacity={1}
                        background="linear-gradient(270deg, #C03DFD -20.4%, #99ACD226 22.91%)"
                        backdropFilter="blur(30px)"
                        rounded="2px"
                        borderRight="2px solid #C03DFD"
                        p="14px"
                      >
                        User B commented on the post
                      </Text>
                    ) : (
                      <Text
                        w="85%"
                        textAlign="end"
                        bg="primary.99"
                        as="span"
                        className="fs--12"
                        opacity={1}
                        background="linear-gradient(270deg, #0DE369 -20.4%, #99ACD226 22.91%)"
                        backdropFilter="blur(30px)"
                        rounded="2px"
                        borderRight="2px solid #0DE369"
                        p="14px"
                      >
                        User A Liked The Post
                      </Text>
                    )}
                    <Flex alignItems="center" ml="17px" direction="column">
                      <Text className="fs--10 fw--300" color="text">
                        Tue
                      </Text>
                      <Text className="fs--14 fw--500">12</Text>
                    </Flex>
                  </Flex>
                )
              })}

              <Box textAlign="center">
                <Button
                  className="fs--12"
                  w="92px"
                  rounded="full"
                  m="0"
                  p="0"
                  colorScheme="secondary"
                >
                  see all
                </Button>
              </Box>
            </Box>
            <Box mt="13px" p="16px" rounded="12px" bg="primary.100">
              <Text className="fs--16" textAlign="end">
                Members
              </Text>
              <Divider mt="6px" mb="12px" />

              {[0, 1, 2].map(item => {
                return (
                  <HStack mb="18px" key={item} justify="space-between">
                    <Text className="fs--14 fw--400" color="text">
                      23: Total Post
                    </Text>

                    <HStack>
                      {' '}
                      <Text className="fs--14 fw--400" color="text">
                        NFT Fan Token
                      </Text>
                      <Avatar
                        boxSize="33px"
                        src={avatar3}
                        className="gradient-border-mask"
                      />
                    </HStack>
                  </HStack>
                )
              })}

              <Box textAlign="center">
                <Button
                  className="fs--12"
                  w="92px"
                  rounded="20px"
                  colorScheme="secondary"
                >
                  see all
                </Button>
              </Box>
            </Box>
          </Box>
          <Box flex={0.7} maxW="635px">
            <Box bg="primary.100" rounded="10px" mb="15px">
              <Flex padding="15px">
                <Textarea
                  placeholder="Share your thoughts"
                  color="text"
                  textAlign="end"
                  variant="unstyled"
                />
                <Avatar
                  ml="10px"
                  name="Segun Adebayo"
                  className="gradient-border-mask"
                  src={avatar2}
                />
              </Flex>
              <Flex
                alignItems="center"
                justifyContent="space-between"
                borderTop="2px solid #3D3F4B"
              >
                <Button
                  className="fs--14 fw--400"
                  w="91px"
                  h="45px"
                  borderRadius="0"
                  borderEndStartRadius="10px"
                >
                  Post
                </Button>
                <Box>
                  {['Giff', 'Video', 'Picture'].map(btn => (
                    <Button
                      key={btn}
                      _focus={{ bg: 'transparent' }}
                      className="fs--14 fw--500"
                      h="90%"
                      color="white"
                      borderRadius="0px"
                      variant="ghost"
                      onClick={() => {
                        alert('This might open a Giff modle....')
                      }}
                      borderLeft="2px solid #3D3F4B"
                      px="30px"
                    >
                      {btn}
                    </Button>
                  ))}
                </Box>
              </Flex>
            </Box>

            <Card bg="primary.100" maxW="100%">
              <CardHeader>
                <Flex justify="space-between" align="center">
                  <Box>
                    <BsThreeDotsVertical
                      fontSize="30px"
                      style={{ fill: 'url(#blue-gradient)' }}
                    />
                  </Box>
                  <HStack>
                    <Box textAlign="end">
                      <Heading className="fs--16" color="white" size="sm">
                        NFT Fan
                      </Heading>
                      <Text className="fs--12" color="text">
                        12 :16 am
                      </Text>
                    </Box>
                    <Avatar
                      name="Segun Adebayo"
                      className="gradient-border-mask"
                      src={avatar3}
                    />
                  </HStack>
                </Flex>
              </CardHeader>
              <CardBody h="352px" py="0px">
                <Image
                  objectFit="cover"
                  src="https://images.unsplash.com/photo-1531403009284-440f080d1e12?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80"
                  alt="Chakra UI"
                />
              </CardBody>

              <CardFooter>
                <Button
                  color="white"
                  flex={0.5}
                  variant="ghost"
                  rightIcon={
                    <RiShareForwardFill
                      fontSize="20px"
                      style={{ fill: 'url(#blue-gradient)' }}
                    />
                  }
                  justifyContent="start"
                >
                  Share
                </Button>

                <Button
                  color="white"
                  flex={0.25}
                  justifyContent="end"
                  variant="ghost"
                  rightIcon={
                    <FaCommentDots
                      fontSize="20px"
                      style={{ fill: 'url(#blue-gradient)' }}
                    />
                  }
                >
                  68 Comment
                </Button>
                <Button
                  color="white"
                  flex={0.25}
                  justifyContent="end"
                  variant="ghost"
                  rightIcon={
                    <AiFillLike
                      fontSize="20px"
                      style={{ fill: 'url(#blue-gradient)' }}
                    />
                  }
                >
                  212 Like
                </Button>
              </CardFooter>
              <Flex
                px="20px"
                justifyContent="end"
                border="0px solid green"
                direction="column"
              >
                <Flex border="0px solid red" justifyContent="end">
                  <Text
                    className="fs--14 fw--400 two-line-ellipsis"
                    color="text"
                  >
                    the dummy username named users @Dr.Dre and KayneWest would
                    look like I have a good record there. Played one,won one,
                    and hopefully it&lsquo;s the dummy username named users
                    @Dr.Dre and KayneWest would look like I have a good record
                    there. Played one,won one, and hopefully it&lsquo;s
                  </Text>
                  <Avatar
                    ml="10px"
                    name="Segun Adebayo"
                    src={avatar3}
                    className="gradient-border-mask"
                  />
                </Flex>
                <Flex pt="10px">
                  <Divider flex={1} height="10px" />
                  <Text className="fs--14 fw--400">View all comments 4</Text>
                  <Divider flex={1} height="10px" />
                </Flex>
                <Flex gap="8px" align="center" py="10px">
                  <InputGroup>
                    <InputLeftElement>
                      <IoSend
                        className="rotate-icon"
                        style={{ fill: 'url(#blue-gradient)' }}
                      />
                    </InputLeftElement>
                    <Input
                      dir="rtl"
                      type="text"
                      borderWidth="1px"
                      placeholder="write something"
                      rounded="full"
                      borderColor="#2F3852"
                      bgColor="#41424A"
                      textColor="white"
                      focusBorderColor="primary.300"
                      color="text"
                    />
                  </InputGroup>
                  <Avatar
                    name="Segun Adebayo"
                    src={avatar3}
                    className="gradient-border-mask"
                  />
                  {/* for Image */}
                </Flex>
              </Flex>
            </Card>
            {/* Post Card Component End */}
            <Flex display="none" pt="100px">
              {/* <Box 
             className='overLayingEffect'             
               bgImage={sideBarCard}
               backgroundSize="cover"
               bgPosition="center"
               bgRepeat="no-repeat"
               boxSize="350px">
                <Flex  h="100%" direction="column" justifyContent="space-between" border="2px solid red">
                  <Button onClick={()=>{alert('testing for the navigate')}}>Mint NFT </Button>
                  <Button className='myTest' onClick={()=>{alert('access alert test Link')}}>
                    we will redirect
                  </Button>
                  </Flex>    
     
                   
             </Box> */}
            </Flex>
          </Box>
        </Flex>
      </>
    </MainLayout>
  )
}
