import { defineStyleConfig } from '@chakra-ui/react'
export const Text = defineStyleConfig({
  // The styles all button have in common
  baseStyle: {
    fontWeight: 'normal',
    textTransform: 'capitalize',
  },
  // Two variants: outline and solid
  variants: {
    outline: {
      color: 'white',
    },
    primary: {
      bgGradient: 'linear-gradient(90deg, #C03DFD, #4F81FD)',
      bgClip: 'text',
    },
  },
  // The default size and variant values
  defaultProps: {
    variant: 'outline',
  },
})
