import { Input } from '@chakra-ui/react'
import React from 'react'
interface IInput {
  type: string
  placeholder: string
}
export default function CustomInput({ type, placeholder }: IInput) {
  return (
    <Input
      dir="rtl"
      type={type}
      borderWidth="1px"
      placeholder={placeholder}
      rounded="full"
      borderColor="#2F3852"
      bgColor="#41424A"
      textColor="white"
      focusBorderColor="primary.300"
    />
  )
}
